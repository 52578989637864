import React, {useCallback, useReducer, useState} from 'react';
import {FilterTableReport} from '../Shared/FilterTableDataComponent/FilterTableDataComponent';
import {Action, Column} from '../Shared/CustomTableComponent/CustomTableComponent';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft, faEye} from '@fortawesome/free-solid-svg-icons';
import {useRouter} from 'next/router';
import {
    AnalyticQueryAction,
    BaseAnalyticQuery,
    ColumnsMetadata,
    Report,
    ReportMetadata,
    TruncateReport
} from "../../types/Analytic";
import PreviewChartResultComponent
    from "../ReportGeneratorComponent/PreviewResultsComponent/PreviewChartResultComponent";
import useAsyncEffect from "../../hooks/useAsyncEffect";
import useTranslate from "../../hooks/useTranslate";
import TabContents from "../Shared/TabContents";
import {getAllCloudScript, updateModelRow} from "../../services/backend/SbxService";
import {State} from "../../types/State";
import {convertDateToDDMMMYYYYHHmm, IsJsonString, removeDuplicateFromArray, success, toast, uuidV4} from "../../utils";
import ButtonComponent from "../Shared/ButtonComponent";
import ExcelButtonComponent from "../Shared/ExcelButtonComponent";
import {MultiReport} from "../analytics/AnalyticsPageComponent";
import TruncateListComponent from "./TruncateListComponent";
import CustomOptimizeTableComponent from "../Shared/CustomTableComponent/CustomOptimizeTableComponent";
import {TableCustom} from "../Shared/CustomTableComponent/Table";
import {getColumnsReport} from "../../utils/analyticsUtils";
import {useSelector} from "react-redux";
import {authReducer} from "../../store/Selectors";
import useMemoAsync from "../../hooks/useMemoAsync";

type Props = {
    reportProps: {
        columns: Column[];
        hideColumns?: Column[];
        remove_empty_columns?: boolean
        filters?: FilterTableReport[];
    }
    data: any[]
    isLoading: boolean;
    showConfig?: boolean;
    isDashboardView?: boolean;
    filtersByParent?: boolean
    report?: Report | MultiReport
    query?: BaseAnalyticQuery
    truncate?: TruncateReport | null,
    setTruncate?: (truncate: TruncateReport) => void
    launchQuery?: (query: Report, reload?: boolean) => void
    tab?: string,
    setReportColumnsProps?: (columns: Column[]) => void
    setTab?: (tab: string) => void
}

enum Types {
    SET_STATE,
    SET_MULTI_STATE
}

interface InitialReducerState {
    data: any[];
    copyData: any[];
    loading: State
    report?: Report
}

const initialState: InitialReducerState = {
    data: [],
    copyData: [],
    loading: State.IDLE,

};

function reducer(state: InitialReducerState, {
    type,
    payload
}: { type: Types, payload: { name: string, value?: any } | { name: string, value: any }[] }) {
    switch (type) {
        case Types.SET_STATE:
            return {
                ...state,
                [(payload as { name: string, value: any }).name]: (payload as { name: string, value: any }).value
            };
        case Types.SET_MULTI_STATE:
            (payload as { name: keyof InitialReducerState, value: any }[]).forEach(data => {
                state = {...state, [data.name]: data.value};
            });
            return {...state};
        default:
            throw new Error();
    }
}


export type reportPropType = 'sort' | 'custom_column' | 'columns_to_summarize' | 'columns_metadata'
const ReportComponent = ({
                             data,
                             isLoading,
                             reportProps,
                             filtersByParent,
                             report,
                             query,
                             launchQuery,
                             truncate, setReportColumnsProps,
                             setTruncate, showConfig, isDashboardView, setTab
                         }: Props) => {
    const {user} = useSelector(authReducer)
    const [localTab, setLocalTab] = useState('0')
    const [hierarchy, setHierarchy] = useState<string[]>([])
    const [filtersDrillDown, setFiltersDrillDown] = useState<string[]>([])
    const [isDrillDownReport, setIsDrillDownReport] = useState(false)
    const [stateLocal, dispatchLocal] = useReducer(reducer, initialState);
    const history = useRouter();

    const isFirstTabRender = React.useRef(true);
    const [tableData, setTableData] = useState<any[]>([])
    const [containsIndexExecution, setContainsIndexExecution] = useState(false)
    const {t} = useTranslate('common');
    const [columns, setColumns] = useState<Column[]>([])
    // const [subColumns, setSubColumns] = useState<Column[]>([])
    const dispatchMultiForm = (forms: { name: keyof InitialReducerState, value: any }[]) => {
        dispatchLocal({type: Types.SET_MULTI_STATE, payload: forms});
    };
    const dispatchForm = ({name, value}: { name: keyof InitialReducerState, value: any }) => {
        dispatchLocal({type: Types.SET_STATE, payload: {value, name}});
    };
    const [actions, setActions] = useState<Action[]>([])
    const [querySources, setQuerySources] = useState<string[]>([])
    const [isSubHeaderReport, setIsSubHeaderReport] = useState(false)
    const onMount = React.useRef(false);
    React.useEffect(() => {
        if (!containsIndexExecution && query && !query.truncate) {
            setContainsIndexExecution(query.actions.some(action => action.hasOwnProperty("drill_down_execution_index")))
        }
    }, [query]);

    React.useEffect(() => {

        if (setTab) {
            setTab(localTab)
        }

    }, [localTab, setTab]);

    useAsyncEffect(async () => {


        const metadata = report?.metadata && IsJsonString(report.metadata as string) ? JSON.parse(report.metadata as string) as ReportMetadata : report?.metadata

        if (isFirstTabRender.current && metadata && Object.keys(metadata).length > 0 && report?.type && !isDashboardView) {
            setLocalTab('1')
            isFirstTabRender.current = false
        }

        dispatchForm({name: 'report', value: report})

    }, [report])

    React.useEffect(() => {

        let newData = [...data]

        newData = newData.map(item => {
            Object.keys(item).forEach(dataKey => {
                if (Array.isArray(item[dataKey])) {
                    item[dataKey] = JSON.stringify(item[dataKey])
                }
            })
            return item
        })

        if (newData.some(row => row.process_execution_id && row.process_execution_process_id)) {
            setActions([
                {
                    type: 'primary',
                    label: <span><FontAwesomeIcon icon={faEye} className="me-1"/>Ver estado</span>,
                    onAction: row => {
                        history.push(`/admin/project-manager/${row.process_execution_process_id}/execution/${row.process_execution_id}`);
                    },
                    visibleRow: row => row.process_execution_id && row.process_execution_process_id
                }
            ])
        }

        if (report?.columns_metadata && IsJsonString(report.columns_metadata as string)){
            const columnsMetadata = JSON.parse(report.columns_metadata as string) as ColumnsMetadata
            if  (columnsMetadata.head_sub_head?.index && columnsMetadata.head_sub_head?.index.length > 0 && columnsMetadata.head_sub_head.head_by){

                setIsSubHeaderReport(true)
                /**
                 * This code snippet processes a report object to transform an array of data rows (`newData`)
                 * into a nested object structure based on metadata specified within the report.
                 *
                 * - It first checks if the `report.columns_metadata` exists and is a valid JSON string.
                 * - If the metadata contains the necessary `index` and `head_by` fields, these fields are used
                 *   to structure the data.
                 *
                 * Specifically, it does the following:
                 *
                 * 1. Parse the `columns_metadata` from the report.
                 * 2. Extract the primary key (`index`) and secondary key (`headBy`) from the metadata.
                 * 3. Use `Array.prototype.reduce` to iterate over `newData` and construct a nested object (`dataByIndex`).
                 *
                 * The resulting `dataByIndex` object structure is as follows:
                 * - Each primary key (`index`) points to another object.
                 * - This nested object contains a secondary key (`headBy`) which in turn points to an object containing
                 *   the remaining key-value pairs from the original row, excluding the `index` and `headBy` keys.
                 *
                 * Example input data (newData):
                 * [
                 *   {id: 1, category: 'A', value: 10},
                 *   {id: 1, category: 'B', value: 20},
                 *   {id: 2, category: 'A', value: 30}
                 * ]
                 *
                 * Example columns metadata:
                 * {
                 *   head_sub_head: {
                 *     index: ['id'],
                 *     head_by: 'category'
                 *   }
                 * }
                 *
                 * Example output (dataByIndex):
                 * {
                 *   1: {
                 *     id: 1,
                 *     A: {value: 10},
                 *     B: {value: 20}
                 *   },
                 *   2: {
                 *     id: 2,
                 *     A: {value: 30}
                 *   }
                 * }
                 *
                 * @param {Object} report - The report object containing columns metadata.
                 * @param {Array} newData - The array of data rows to be transformed.
                 * @returns {Object} dataByIndex - The transformed nested object structure.
                 */

                const indices = columnsMetadata.head_sub_head.index;
                const headBy = columnsMetadata.head_sub_head.head_by;

                const dataByIndex = newData.reduce((acc: { [key: string]: { [key: string]: any } }, row) => {
                    const indexKey = indices.map(index => row[index]).join('_');


                    if (indices.every(index => row[index]) && row[headBy] && !acc[indexKey]) {
                        acc[indexKey] = indices.reduce((obj, index) => {
                            obj[index] = row[index];
                            return obj;
                        }, {} as { [key: string]: any });
                    }

                    if (row && row[headBy] && indices.every(index => row[index]) && acc[indexKey] && !acc[indexKey][row[headBy]]) {
                        acc[indexKey][row[headBy]] = {};
                    }

                    Object.keys(row).forEach(key => {
                        if (!indices.includes(key) && key !== headBy && indices.every(index => row[index]) && row[headBy] && acc[indexKey][row[headBy]]) {
                            acc[indexKey][row[headBy]][key] = row[key];
                        }
                    });

                    if (indices.every(index => row[index]) && row[headBy]) {
                        if (acc[indexKey]![row[headBy]]) {
                            indices.forEach(index => {
                                if (acc[indexKey]![row[headBy]]![index]) {
                                    delete acc[indexKey]![row[headBy]]![index];
                                }
                            });

                            if (acc[indexKey]![row[headBy]]) {
                                delete acc[indexKey]![row[headBy]]![headBy];
                            }
                        }
                    } else {
                        // toast({
                        //     message: `Error in the report metadata, please check the configuration. \nCheck ${!row[index] ? `index "${index}"` : ""}, ${!row[headBy] ? `headBy "${headBy}"` : ""} in the report data.`,
                        //     type: "error"
                        // })
                    }

                    return acc;
                }, {});

                /**
                 * Transforms `dataByIndex` into an array (`newData`) and generates a column configuration (`newColumnsTable`).
                 *
                 * 1. Checks if `dataByIndex` has entries.
                 * 2. Converts `dataByIndex` values to `newData`.
                 * 3. Constructs `newColumnsTable` based on `newData` keys and sub-keys.
                 * 4. Updates the columns state using `setColumns`.
                 *
                 * @param {Object} dataByIndex - Nested object structure derived from `newData`.
                 * @param {Object} reportProps - Contains the original column definitions.
                 * @param {Function} setColumns - Function to update the columns state.
                 */


                if (Object.keys(dataByIndex).length > 0) {
                    newData = Object.values(dataByIndex);

                    const orderMap = new Map(reportProps.columns.map((item, index) => [item.name, index]));
                    const sortedArray = Object.keys(newData[0]).sort((a, b) => {
                        const indexA = orderMap.get(a);
                        const indexB = orderMap.get(b);

                        if (indexA !== undefined && indexB === undefined) return -1;
                        if (indexA === undefined && indexB !== undefined) return 1;
                        if (indexA !== undefined && indexB !== undefined) return indexA - indexB;
                        return 0;
                    });

                    const newColumnsTable = sortedArray.map(key => {
                        const column = reportProps.columns.find(column => column.name === key);

                        const colSpan = Object.keys(newData[0][key]).length;
                        const subColumns = Object.keys(newData[0][key]).map(subKey => {
                            const prevColumn = reportProps.columns.find(column => column.name === subKey);

                            return prevColumn ? {
                                ...prevColumn,
                                name: column?.name,
                                value: subKey,
                                type: prevColumn.type ?? column?.type ?? "String"
                            } : { name: column?.name, value: subKey, header: subKey, type: column?.type ?? "String" };
                        });

                        if (column) {
                            return {
                                ...column,
                                colSpan,
                                rowSpan: indices.includes(key) ? 2 : 1,
                                sub_columns: indices.includes(key) ? [] : subColumns
                            };
                        }
                        return {
                            name: key,
                            header: key,
                            colSpan,
                            rowSpan: indices.includes(key) ? 2 : 1,
                            sub_columns: indices.includes(key) ? [] : subColumns
                        };
                    });
                    setColumns(newColumnsTable as Column[]);
                } else {
                    setColumns(reportProps.columns ?? []);
                }

            } else {
                setColumns(reportProps.columns ?? [])
            }
        } else {
            setColumns(reportProps.columns ?? [])
        }



        dispatchMultiForm([
            {name: 'copyData', value: newData},
            {name: 'data', value: newData}
        ]);

    }, [data, report, reportProps.columns]);

    React.useEffect(() => {
        if ((query)?.actions && query.actions.length > 0) {
            const action = query.actions.some(action => action.subtype === "drill_down")
            // console.log('action', action)
            if (action) {
                setIsDrillDownReport(true)
            }
        }

        if (query) {
            const nSources: string[] = [query.source.with]

            if (query.source.sources && query.source.sources.length > 0) {
                const sourcesWith = query.source.sources.map(source => source.with);
                nSources.push(...sourcesWith);
            }
            setQuerySources(nSources)
        }

    }, [query]);

    const availableDrillDown = (actionHierarchy: string[], columnName: string) => {
        const level = getDrillDownLevel()
        const columnIndex =actionHierarchy.indexOf(columnName)

         return isDrillDownReport
             && columnIndex !== -1
             && actionHierarchy.includes(columnName)
             && level <= columnIndex && (actionHierarchy[actionHierarchy.length - 1] !== columnName)
    }

    const checkColumnDrill = (name: string) => {
        if (!query) return false
        const action = query.actions.find(action => action.subtype === "drill_down")


        if (action?.hierarchy && availableDrillDown(action?.hierarchy as string[], name)) {
            return availableDrillDown(action?.hierarchy as string[], name)
        }

        return false
    }

    const cloudscripts = useMemoAsync(async () => {
        // setLoading(true)
        if (columns.some(column => column.type === "cloudscript")){
            const res = await getAllCloudScript();
            if (res?.success && res.items) {
                return res.items
            }
        }

        // setLoading(false)
    }, [columns])

    React.useEffect(() => {
        const {hierarchy_value, hierarchy} = history.query

        if (hierarchy && hierarchy_value && stateLocal.data.length > 0
            && !onMount.current && checkColumnDrill(hierarchy as string)) {
            drillDown(hierarchy_value as string, hierarchy as string)
            onMount.current = true
        }
    }, [history.route, stateLocal.data]);

    const drillDown = (value: string | number, name: string) => {
        if (query) {

            const nQuery = {...query}

            const action = nQuery.actions.find(action => action.subtype === "drill_down")
            if (isDrillDownReport && action?.hierarchy && checkColumnDrill(name)) {
                let hierarchyList = hierarchy.length === 0 ? [action.hierarchy[0]] : [...hierarchy];
                const nextColumn = action.hierarchy.find(column => !hierarchyList.includes(column));

                // const nextColumn = action.hierarchy.filter(column => !hierarchyList.includes(column))[0]

                if (nextColumn) {
                    hierarchyList.push(nextColumn)
                    setHierarchy(hierarchyList)

                    if (launchQuery) {
                        const filters = [...filtersDrillDown, typeof value === "number" ? `${name} == ${value}` : `${name} == '${value}'`]

                        const filter: AnalyticQueryAction = {
                            type: "filter",
                            filter: filters.join(" & "),
                            temporal_id: uuidV4()
                        }

                        setFiltersDrillDown(filters)

                        if (report?.metadata && IsJsonString(report.metadata as string) && JSON.parse(report.metadata as string).x_axis) {
                            const metadata = JSON.parse(report.metadata as string) as ReportMetadata
                            report = {
                                ...report,
                                metadata: JSON.stringify({...metadata, x_axis: hierarchyList[hierarchyList.length - 1]})
                            }
                        }
                        launchQueryForDrillDown({isDrillDownQuery: true, filter, report, hierarchyList})
                    }
                }
            }
        }
    }

    const launchQueryForDrillDown = ({hierarchyList, isDrillDownQuery, filter, report}: {
        isDrillDownQuery?: boolean,
        filter: AnalyticQueryAction,
        hierarchyList: string[]
        report?: Report | MultiReport
    }) => {
        if (query && launchQuery) {

            launchQuery({
                ...report as Report, isDrillDownQuery, drillDownLevel: hierarchyList.length - 1, query: JSON.stringify({
                    ...query, actions: [...query.actions.map((action, index) => {
                        if (action.subtype && action.subtype === "drill_down") {

                            action = {...action, columns: [...action.columns as string[], ...hierarchyList]}
                        }
                        return action;
                    }), filter]
                })
            }, containsIndexExecution)
        }
    }

    const backDrillDown = () => {
        if (launchQuery && query) {
            if (hierarchy.length <= 2) {
                if (report?.metadata && IsJsonString(report.metadata as string) && JSON.parse(report.metadata as string).x_axis) {
                    const metadata = JSON.parse(report.metadata as string) as ReportMetadata
                    if (report) {
                        report = {...report, metadata: JSON.stringify({...metadata, x_axis: hierarchy[0]})}
                    }
                }
                launchQuery({
                    ...report as Report,
                    drillDownLevel: 0,
                    query: JSON.stringify(query)
                }, containsIndexExecution)
                setFiltersDrillDown([])
                setHierarchy([])
            } else {
                const hierarchyList = [...hierarchy]
                hierarchyList.pop()
                setHierarchy(hierarchyList)
                const filterList = [...filtersDrillDown]
                filterList.pop()
                setFiltersDrillDown(filterList)

                const filter: AnalyticQueryAction = {
                    type: "filter",
                    filter: filterList.join(" & "),
                    temporal_id: uuidV4()
                }

                if (hierarchyList.length === 2 && report?.metadata && IsJsonString(report.metadata as string) && JSON.parse(report.metadata as string).x_axis) {
                    const metadata = JSON.parse(report.metadata as string) as ReportMetadata
                    if (report) {
                        // console.log("JSON.stringify({...metadata, x_axis: name})", JSON.stringify({...metadata, x_axis: hierarchyList[0]}))
                        report = {...report, metadata: JSON.stringify({...metadata, x_axis: hierarchyList[0]})}
                    }
                }


                launchQueryForDrillDown({hierarchyList, filter, report, isDrillDownQuery: true})
            }
        }
    }

    const backDrillDownButton = () => isDrillDownReport && hierarchy.length > 0 && <div className="mb-3">
        <ButtonComponent label={t("back")} disabled={isLoading || stateLocal.loading === State.PENDING}
                         loading={isLoading || stateLocal.loading === State.PENDING} icon={faArrowLeft}
                         onClick={backDrillDown}/>
    </div>

    const getDrillDownLevel = () => {
        if (isDrillDownReport && hierarchy.length > 0) {
            return hierarchy.length - 1
        }

        return 0
    }


    const getDefaultReport = () => {
        // console.log('tableData', tableData)
        return <div className="d-flex flex-column" >

            {!filtersByParent && isSubHeaderReport &&
                <div className="d-flex align-items-center justify-content-end my-2 gap-3">
                    <ExcelButtonComponent fileName={`${report?.name}_${convertDateToDDMMMYYYYHHmm(new Date())}`}
                                          disabled={isLoading} excelHeaders={reportProps.columns.filter(column => {
                        if (isSubHeaderReport) {

                            const data = tableData.length > 0
                                ? tableData : stateLocal.data
                            if (data.length > 0) {
                                return !!data[0][column.name]
                            }

                        }
                        return !["total_column_by_row"].includes(column.name)
                    }).map(column => ({
                        label: column.header,
                        value: column.name
                    }))} hasSubHeader={isSubHeaderReport} excelData={tableData.length > 0
                        ? tableData : stateLocal.data} isLoading={isLoading}/>
                </div>
            }

            {backDrillDownButton() ?? null}

            <CustomOptimizeTableComponent cloudscripts={cloudscripts} sortableTable={user?.config?.sbx_crm?.report?.config?.table_sort_columns !== undefined
                ? user?.config?.sbx_crm?.report?.config.table_sort_columns : true} actions={actions} useLocalPage columnsSetting={user?.config?.sbx_crm?.report?.config?.table_manage_columns !== undefined
                ? user?.config?.sbx_crm?.report?.config.table_manage_columns : true } key={`table_${columns.length}_${stateLocal.data.length}_${columns.reduce((acc, column) => acc + (column.sub_columns ? column.sub_columns?.length : 0), 0)}`}
                                  getData={data => setTableData(data)}
                                  removeEmptyColumns={reportProps.remove_empty_columns} isReportTable
                                  hideColumnsReports={reportProps.hideColumns}
                                          exportButtons={(isSubHeaderReport || filtersByParent) ? [] : [
                                              {type: "xls", color: "secondary", fileName: `${report?.name}_${convertDateToDDMMMYYYYHHmm(new Date())}`}
                                          ]}
                                  updateColumnsProps={reportColumnPropsUpdate} loadSbxModels={querySources}
                                  columns={columns.map(column => {
                                      if (column.type?.toString()?.includes("money")) {
                                          column.className = "text-end"
                                      }
                                      if (query?.actions && query.actions.length > 0) {
                                          const action = query.actions.find(action => action.subtype === "drill_down")

                                          if (action?.hierarchy && availableDrillDown(action?.hierarchy as string[], column.name)) {
                                              column.className = (column.className ?? "") + " zoom-in d-flex align-items-center justify-content-between"
                                              column.isDrillDown = true
                                          }
                                      }

                                      column.className = column.className ? removeDuplicateFromArray(column.className.split(" ")).join(" ") : ""

                                      if (column.sub_columns && column.sub_columns.length > 0) {
                                          column.sub_columns = column.sub_columns.map(subColumn => {
                                              if (column.className) {
                                                  subColumn.className = column.className
                                              }


                                              return subColumn
                                          })
                                      }
                                      return column
                                  })} data={stateLocal.data}
                                  rowAction={(row, header) => {
                                      drillDown(row[header.name], header.name)
                                  }}
                                  loading={isLoading || stateLocal.loading === State.PENDING}/>
        </div>
    }

    const parseReportProps = (propItems: string) => {
        if (IsJsonString(propItems)) {
            return JSON.parse(propItems)
        } else {
            if (Array.isArray(propItems)) {
                return propItems
            }
        }

        return null
    }

    const getCustomItems = ({propItems, subProp, value, column_name}: {propItems: string, column_name: string, value: any, subProp?: string}) => {
        let items = parseReportProps(propItems) as { column: string }[] ?? []
        if ((!value && Array.isArray(items) && (items).some(column => column.column === column_name) || items.some(column => column.column === column_name))) {
            items = items.filter(column => column.column !== column_name)
        }

        if (value) {
            const obj = {column: column_name, type: subProp || value, ...(subProp && {value})};
            items.push(obj);
        }

        return items
    }

    const getSummarizeColumns = ({propItems, column_name, value}: {propItems: string, column_name: string, value: any}) => {
        let items = parseReportProps(propItems) as string[] ?? []
        if (value) {
            if (!items.some(item => item === column_name)) {
                items.push(column_name)
            }
        } else {
            items = items.filter(item => item !== column_name)
        }

        return items
    }

    const reportColumnPropsUpdate = async ({reportProp, column_name, value, subProp}: {
        column_name: string,
        value: any, subProp?: string,
        reportProp: reportPropType
    }) => {

        if (stateLocal?.report?._KEY) {


            const newReport: { [key: string]: any } = {
                _KEY: stateLocal.report._KEY
            }

            let propItems = ''
            if (stateLocal.report && stateLocal.report[reportProp as keyof Report]) {
                propItems = stateLocal.report[reportProp as keyof Report] as string
            }



            const reportColumns = reportProps.columns

            switch (reportProp) {
                case "columns_to_summarize": {

                    const items = getSummarizeColumns({propItems, column_name, value})

                    newReport.columns_to_summarize = JSON.stringify(items)
                }
                    break;

                case "columns_metadata": {
                    let items = parseReportProps(propItems) as { [key: string]: any[] } ?? {}
                    if (subProp) {
                        const obj: {
                            [key: string]: any[]
                        } = Object.keys(items).length > 0 ? {...items} : {[subProp]: []}

                        if (!obj[subProp]) {
                            obj[subProp] = []
                        }

                        if (value) {
                            if (!obj[subProp].some(item => item === column_name)) {
                                obj[subProp].push(column_name)
                            }
                        } else {
                            if ((!value && obj[subProp].some(column => column === column_name))) {
                                obj[subProp] = obj[subProp].filter(column => column !== column_name)
                            }
                        }

                        newReport.columns_metadata = JSON.stringify(obj)

                        // Validate if the column is a Total column by row, that is custom, delete all config for special column
                        if ((!obj[subProp] || obj[subProp].length === 0) && subProp === 'sum_rows') {
                            if (stateLocal.report && stateLocal.report['custom_column' as keyof Report]) {
                                propItems = stateLocal.report['custom_column' as keyof Report] as string
                            }
                            const items = getCustomItems({
                                propItems,
                                column_name: "total_column_by_row",
                                value: "",
                                subProp
                            })
                            newReport.custom_column = JSON.stringify(items)

                            if (stateLocal.report && stateLocal.report['columns_to_summarize' as keyof Report]) {
                                propItems = stateLocal.report['columns_to_summarize' as keyof Report] as string
                            }
                            const itemsSummarize = getSummarizeColumns({propItems, column_name: "total_column_by_row", value: false})
                            newReport.columns_to_summarize = JSON.stringify(itemsSummarize)
                        }


                    }
                }

                    break;

                case "sort":
                    const level = getDrillDownLevel()
                    let newSort = {
                        [level]: value
                    }

                    if (report?.sort && IsJsonString(report.sort)) {
                        const sort = JSON.parse(report.sort)
                        if (Array.isArray(sort)) {
                            if (level !== 0) {
                                newSort = {0: sort, ...newSort}
                            }
                        } else {
                            newSort = {...sort, ...newSort}
                        }
                    }

                    // console.log("newSort", newSort)
                    // console.log("JSON.stringify(newSort)", JSON.stringify(newSort))
                    newReport.sort = JSON.stringify(newSort)
                    break;

                case "custom_column": {
                    const items = getCustomItems({propItems, column_name, value, subProp})
                    newReport.custom_column = JSON.stringify(items)
                }
                    break
            }

            if (newReport._KEY) {
                toast({
                    message: t("custom-message:updating-report"),
                    type: "info"
                })
                dispatchForm({name: "report", value: {...stateLocal.report, ...newReport as any as Report}})
                getColumnsReport({...report, ...stateLocal.report, ...newReport as any as Report}, columns.some(column => column.sub_columns && column.sub_columns.length > 0) ? data : [], reportColumns).then(res => {
                    setReportColumnsProps && setReportColumnsProps(res.columns)
                })

                const response = await updateModelRow([newReport], "sbx_crm_report")
                if (response?.success) {
                    success(t("custom-message:report-updated"))
                }
            }
        }
    }

    const getReportChart = useCallback((report: MultiReport | Report) => {

        const report_data = (report as MultiReport)?.data ?? stateLocal.data

        const metadata: ReportMetadata | undefined = report.metadata && IsJsonString(report.metadata as string) ? JSON.parse(report.metadata as string) : report.metadata

        return <div >
            {backDrillDownButton() ?? null}
            <PreviewChartResultComponent isClientView={showConfig}
                                         loading={isLoading || stateLocal.loading === State.PENDING}
                                         setReport={async data => {
                                             if (launchQuery) {
                                                 launchQuery({
                                                     ...report,
                                                     ...data,
                                                     metadata: JSON.stringify(data.metadata)
                                                 })
                                             }
                                             if (report?._KEY) {
                                                 dispatchForm({name: "loading", value: State.PENDING})

                                                 const res = await updateModelRow([{
                                                     _KEY: report._KEY,
                                                     metadata: data.metadata,
                                                     type: data.type ?? ""
                                                 }], "sbx_crm_report")

                                                 if (res?.success) {
                                                     dispatchForm({name: "loading", value: State.RESOLVED})
                                                 } else {
                                                     dispatchForm({name: "loading", value: State.REJECTED})
                                                 }
                                             }
                                         }}
                                         drillDown={drillDown}
                                         columns={reportProps.columns.map(column => {

                                             let isDrillDownColumn = false

                                             if (query?.actions && query.actions.length > 0) {
                                                 const action = query.actions.find(action => action.subtype === "drill_down")
                                                 if (action && isDrillDownReport && (!hierarchy.includes(column.name) || (hierarchy.length === 2 && hierarchy[0] !== column.name)) && action.hierarchy!?.slice(0, action.hierarchy.length - 1).includes(column.name)) {
                                                     isDrillDownColumn = true
                                                 }

                                             }

                                             return {
                                                 label: column.header,
                                                 value: column.name,
                                                 isDrillDownColumn

                                             }
                                         })} results={report_data} type={report.type} metadata={metadata}/>
        </div>


    }, [report?.type, report?.metadata, isLoading, stateLocal.data, stateLocal.loading, showConfig, reportProps.columns]);

    const getItemsFromTableColumn = (column: string) => {
        const table = new TableCustom({
            data: stateLocal.data,
            columns: reportProps.columns,
        });

        return table.columnItems({name: column} as Column);
    }

    return <>

        {report && <TruncateListComponent disabled={isLoading || stateLocal.loading === State.PENDING} report={report} getItemsFromTableColumn={getItemsFromTableColumn} launchQuery={launchQuery} setTruncate={setTruncate}
                                          truncate={truncate} query={query}/>}
        {report ?
            <div className="d-flex flex-column">


                {isDashboardView ?

                    report ? getReportChart(report) : null

                    :

                    <TabContents tap={localTab} getCurrentTab={setLocalTab} tabs={[{
                        key: "table",
                        label: t("table-view"),
                        component: getDefaultReport()
                    },
                        {label: t("graph-view"), key: "graph", component: report ? getReportChart(report) : null}
                    ]}/>
                }


            </div> : getDefaultReport()}


    </>
};

export default ReportComponent;
