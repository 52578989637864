import * as React from 'react';
import {useState} from 'react';
import {CustomTableComponent, TableIProps} from "./CustomTableComponent";
import {TableCustom} from "./Table";
import {DEFAULT_SIZE, filterDataV2} from "../../../utils";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/Reducers";


const CustomOptimizeTableComponent = (props: TableIProps & {getTableData?: (data: any[]) => void}) => {
    const {page, text} = useSelector((state: RootState) => state.SearchReducer);
    const [tableCustom, setTableCustom] = useState<TableCustom>(new TableCustom({
        data: [],
        columns: [],
        pageSize: DEFAULT_SIZE,
        currentPage: 1
    }))

    const [tableData, setTableData] = useState<{data: any[], totalData: number}>({
        data: [], totalData: 0
    })

    React.useEffect(() => {
        const table = new TableCustom({
            data: props.data,
            columns: props.columns,
            pageSize: 15,
            currentPage: props.currentPage ? props.currentPage : 1
        });

        setTableData({
            data: table.getTableDataByPage(),
            totalData: table.getTableData().length
        })
        setTableCustom(table)

    }, [props]);

    React.useEffect(() => {
        if (text.length > 0) {
            tableCustom.setTableData(filterDataV2(props.data, text));
        } else {
            tableCustom.setTableData(props.data);
        }

        tableCustom.setCurrentPage(1);

        setTableData({
            data: tableCustom.getTableDataByPage(),
            totalData: tableCustom.getTableData().length
        })
    }, [page, text, props]);

    React.useEffect(() => {
        if(props.getTableData){
            props.getTableData(tableCustom.getTableData())
        }
    }, [tableData.data]);

    return (
        <CustomTableComponent {...props}
                              data={tableData.data}
                              currentPage={tableCustom.getCurrentPage()}
                              totalData={tableData.totalData} tableData={tableCustom}
                              onChangePage={(page) => {
                                  tableCustom.setCurrentPage(page);

                                  setTableData({
                                      data: tableCustom.getTableDataByPage(),
                                      totalData: tableCustom.getTableData().length
                                  })
                              }} onShowSizeChange={(page, size) => {

            tableCustom.setCurrentPage(1);
            tableCustom.setPageSize(size);
            setTableData({
                data: tableCustom.getTableDataByPage(),
                totalData: tableCustom.getTableData().length
            })
        }}/>
    );
};

export default CustomOptimizeTableComponent